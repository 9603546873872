import React  from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import HeroNotFound from '../components/404'
import  "../styles/321global.css"
import TopNav from '../components/business-coverage/TopNav'

const QuotePage = () => {

    return (
        <div id="search_coverage">
            <TopNav></TopNav>
            <Header />
                <HeroNotFound  heroHeadline="404" heroSubhead="The page you requested could not be found." />
            <Footer />
        </div>


     );
}

export default QuotePage;
