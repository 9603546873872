import React from 'react';


export default function HeroNotFound(props) {

    return (
        <div id="not_found_hero">
            <div className="hero-text">
                <div id="hero_headline"><h1 >{props.heroHeadline} </h1></div>
                <div id="hero_subhead"><h3>{props.heroSubhead}</h3></div>
            </div>
        </div> 
    )
}
